import client from './client';
import { getUserTokenData } from '../utils/get-user-token-data';

export const getFileBlob = async (url: string) => {
    const token = getUserTokenData()?.token;

    return (
        await client.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
    ).data;
};
