import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

import {
    sectionBox,
    grid,
    topWrapper,
    container,
    radiusBox,
    divider,
    templateTitle,
    wrapper,
    loader,
    buttons,
    isLoading,
    opacity,
    download,
} from './client-mail-template-preview.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useClientMailTemplate } from '../../hooks/use-client-mail-template';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useModal } from '../../hooks/use-modal';
import { useBackLink } from '../../hooks/use-back-link';
import { getFileBlob } from '../../api-clients/get-file-blob';

import Section from '../hoc/section';
import DashboardHeader from '../molecules/dashboard-header';
import RadiusBox from '../hoc/radius-box';
import DetailsColumn from '../atoms/details-column';
import Loader from '../atoms/loader';
import Button from '../atoms/button';
import IconFactory from '../hoc/icon-factory';
import NoPermissionInfo from '../organisms/no-permission-info';

interface IClientMailTemplatePreviewProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientMailTemplatePreviewQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientMailTemplatePreview: React.FC<IClientMailTemplatePreviewProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('ClientMailTemplatePreview');
    const { addModal } = useModal();
    const templateId = getUrlParamValue('id');
    const template = useClientMailTemplate({ queries: ['single'], templateId });
    const templateData = template.single.data;
    const { allPage } = useStaticQuery<IClientMailTemplatePreviewQueryResult>(query);
    const fallbackPathname = usePagePathname(allPage, 'client-mail-templates');
    const clientMailTemplateCreatePage = usePagePathname(allPage, 'client-mail-template-form');
    const prevPathname = useBackLink(fallbackPathname);

    const handleTemplateDelete = () => {
        if (!templateId) return;
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleTemplateDeleteConfirmation(templateId) },
        });
    };

    const handleTemplateDeleteConfirmation = (templateId: number | string) => {
        return async () => {
            try {
                await template.delete.fetch(templateId).unwrap();
                navigate(prevPathname);
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    const handleFileDownload = (mediaItem: IMedia) => {
        return async () => {
            try {
                const blob = await getFileBlob(mediaItem.url);
                const url = URL.createObjectURL(blob);
                window.open(url);
            } catch {}
        };
    };

    if (template.single.isUnauthorized) {
        return <NoPermissionInfo reason="role" isFullHeight={true} />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${template.single.isLoading ? isLoading : ''} ${
                template.delete.isLoading ? opacity : ''
            }`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {templateData ? (
                <div className={container}>
                    <div className={topWrapper}>
                        <DashboardHeader hint={t.hint} TitleTag={TitleTag}>
                            {t.title}
                        </DashboardHeader>
                        <div className={buttons}>
                            <Button
                                stylePreset="danger"
                                size="medium"
                                shape="circle"
                                onClick={handleTemplateDelete}
                            >
                                <IconFactory icon="trash" />
                            </Button>
                            <Button
                                stylePreset="secondary"
                                size="medium"
                                shape="circle"
                                as="link"
                                to={`${clientMailTemplateCreatePage}?id=${templateData.templateId}`}
                            >
                                <IconFactory icon="edit" />
                            </Button>
                        </div>
                    </div>
                    <RadiusBox className={radiusBox} hasBorder={false}>
                        <p className={templateTitle}>
                            {t.id}: {templateData.templateId}
                        </p>
                        <div className={divider} />
                        <div className={wrapper}>
                            <DetailsColumn label={t.name} values={[`${templateData.name}`]} />
                            <DetailsColumn
                                label={t.language}
                                values={[`${templateData.language.toUpperCase()}`]}
                            />
                            <DetailsColumn
                                label={t.createdAt}
                                values={[
                                    `${getDateFromUnixTimestamp(templateData.createdAt, 'dash')}`,
                                ]}
                            />
                            <DetailsColumn label={t.author} values={[`${templateData.author}`]} />
                        </div>
                        <DetailsColumn label={t.subject} values={[`${templateData.subject}`]} />
                        <DetailsColumn label={t.content} values={[`${templateData.content}`]} />
                        {templateData.media && templateData.media.length > 0 && (
                            <DetailsColumn label={t.attachments}>
                                {templateData.media.map((file) => {
                                    return (
                                        <button
                                            className={download}
                                            onClick={handleFileDownload(file)}
                                        >
                                            {file.name}
                                        </button>
                                    );
                                })}
                            </DetailsColumn>
                        )}
                    </RadiusBox>
                </div>
            ) : (
                <Loader className={loader} />
            )}
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-mail-template-form", "client-mail-templates"] } }) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ClientMailTemplatePreview;
