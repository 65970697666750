// extracted by mini-css-extract-plugin
export var backgroundAnimation = "client-mail-template-preview-module--backgroundAnimation--b28c0";
export var buttons = "client-mail-template-preview-module--buttons--1bb25";
export var container = "client-mail-template-preview-module--container--3b268";
export var divider = "client-mail-template-preview-module--divider--79084";
export var download = "client-mail-template-preview-module--download--857f1";
export var grid = "client-mail-template-preview-module--grid--74f4e";
export var isLoading = "client-mail-template-preview-module--is-loading--f258c";
export var loader = "client-mail-template-preview-module--loader--c00ce";
export var opacity = "client-mail-template-preview-module--opacity--16128";
export var radiusBox = "client-mail-template-preview-module--radius-box--a264b";
export var sectionBox = "client-mail-template-preview-module--section-box--44d4d";
export var templateTitle = "client-mail-template-preview-module--template-title--ba743";
export var topWrapper = "client-mail-template-preview-module--top-wrapper--e28e8";
export var wrapper = "client-mail-template-preview-module--wrapper--1e517";